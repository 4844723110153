/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Navbar from '../../../../components/Navbar/Navbar'
import {
  GetOneDocument,
  GetSpecificDocuments,
  UpdateOneDocument
} from '../../../../../common/functions'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import './ManageGuestGroup.css'
import { db } from '../../../../../fire'
import { collection, query, where, doc, updateDoc, onSnapshot } from 'firebase/firestore'

const editGroup = () => {
  const [groupMembers, setGroupMembers] = useState([])
  const [group, setGroup] = useState()
  const location = useLocation()
  const [pathName, setPathName] = useState()
  const [guestOption, setGuestsOption] = useState([])
  const [groupName, setGroupName] = useState()
  const [createGroupClass, setCreateGroupClass] = useState('hide')
  const [selected, setSelected] = useState()
  const [announcement, setAnnouncement] = useState()
  const [invintation, setInvintation] = useState()

  const ReloadMembers = () => {
    GetSpecificDocuments('people', pathName, 'groupId').then((data) => {
      setGroupMembers(data)
    })
  }

  useEffect(() => {
    if (location) {
      console.log(location.pathname)
      let tmp = location.pathname.slice(
        location.pathname.lastIndexOf('/') + 1,
        location.pathname.length
      )
      setPathName(tmp)
    }
  }, [location])

  useEffect(() => {
    if (pathName) {
      GetOneDocument('group', pathName).then((data) => {
        if (data !== null) {
          setGroup(data)
          setAnnouncement(data.announcement)
          setInvintation(data.invintation)
          setGroupName(data.name)
        }
      })

      ReloadMembers()
    }
  }, [pathName])

  const deleteMember = (e) => {
    const id = e.target.dataset.id
    console.log(id)
    UpdateOneDocument('people', id, null, 'groupId').then(() => {
      ReloadMembers()
    })
  }

  const ReadRealTimeData = (q, setData) => {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = []
      querySnapshot.forEach((doc) => {
        data.push({ value: doc.data().id, label: doc.data().name })
      })
      setData(data)
    })
    return setData
  }

  useEffect(() => {
    const q = query(collection(db, 'people'), where('groupId', '==', null))
    ReadRealTimeData(q, setGuestsOption)
  }, [])

  const addToTheGroup = () => {
    setCreateGroupClass('show')
  }

  const deleteChanges = (e) => {
    e.preventDefault()
    setGroupName(group.name)
    setAnnouncement(group.announcement)
    setInvintation(group.invintation)
    setSelected()
    setCreateGroupClass('hide')
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (groupName != '' && groupName != group.name) {
      UpdateOneDocument('group', group.id, groupName, 'name').then(() => {
        console.log('Document updated')
      })
    }
    if (announcement != group.announcement) {
      UpdateOneDocument('group', group.id, announcement, 'announcement')
    } else if (group.announcement == null) {
      UpdateOneDocument('group', group.id, 1, 'announcement')
    }

    if (invintation != group.invintation) {
      UpdateOneDocument('group', group.id, invintation, 'invintation')
    } else if (group.invintation == null) {
      UpdateOneDocument('group', group.id, 1, 'invintation')
    }
    if (selected.length > 0) {
      selected.map((member) => {
        const memberRef = doc(db, 'people', member.value)
        updateDoc(memberRef, {
          groupId: group.id
        })
      })
    }

    ReloadMembers()
    setSelected()
    setGroupName(group.name)
    setAnnouncement(group.announcement)
    setInvintation(group.invintation)
    setCreateGroupClass('hide')
  }
  return (
    <React.Fragment>
      <Navbar />
      <div className="PageTop">
        <h1 className="mainHeading">{group != undefined ? `${group.name} - ${group.id}` : ' '}</h1>
        {createGroupClass == 'hide' ? (
          <button onClick={addToTheGroup} className="btnAdd">
            Upraviť skupinu
          </button>
        ) : (
          <button onClick={(e) => deleteChanges(e)} className="btnAdd">
            Zrušiť
          </button>
        )}
      </div>
      <form className={createGroupClass}>
        <fieldset className="fieldsetArea">
          <legend>Pridať členov</legend>
          <div className="pContainer">
            <p>
              <label htmlFor="name">Názov skupiny</label>
              <input
                type="text"
                name="name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </p>
            <p>
              <label htmlFor="name">Oznámenie</label>
              <input
                type="number"
                name="announcement"
                value={announcement}
                onChange={(e) => setAnnouncement(e.target.value)}
              />
            </p>
            <p>
              <label htmlFor="name">Pozvánka</label>
              <input
                type="number"
                name="invintation"
                value={invintation}
                onChange={(e) => setInvintation(e.target.value)}
              />
            </p>
          </div>
          <Select
            isMulti
            options={guestOption}
            onChange={(e) => setSelected(e)}
            value={selected || ''}
          />
          <button onClick={handleSubmit} className="btnAdd">
            Uložiť zmeny
          </button>
        </fieldset>
      </form>
      <table className="dbTable">
        <tr>
          <th>p.č</th>
          <th>Meno</th>
          <th>Upraviť</th>
        </tr>
      </table>
      {groupMembers.map((member) => (
        <table className="dbTable" key={member.id}>
          <tr>
            <td>{groupMembers.indexOf(member) + 1}</td>
            <td>{member.name}</td>
            <td>
              <FontAwesomeIcon
                className="deleteIcon"
                icon={faXmark}
                data-id={member.id}
                onClick={deleteMember}
              />
            </td>
          </tr>
        </table>
      ))}
      <p className="mainHeading">{group != undefined ? `Počet oznámení: ${announcement}` : ' '}</p>
      <p className="mainHeading">{group != undefined ? `Počet pozvánok: ${invintation}` : ' '}</p>
    </React.Fragment>
  )
}

export default editGroup
