/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import Navbar from '../../../../components/Navbar/Navbar'
import { db } from '../../../../../fire'
import { collection, query, onSnapshot, setDoc, deleteDoc, where, doc } from 'firebase/firestore'
import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import Popup from 'reactjs-popup'
import { GetIdFromFieldName, UpdateOneDocument } from '../../../../../common/functions'
import 'reactjs-popup/dist/index.css'
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@table-library/react-table-library/table'
import './ManageGuests.css'

const ManageGuests = () => {
  const [name, setName] = useState()
  const [guests, setGuestsData] = useState([])
  const [search, setSearch] = React.useState('')
  const [newName, setNewName] = useState()
  const tableData = {
    nodes: guests.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
  }
  const inputRef = useRef(null)
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    const q = query(collection(db, 'people'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const people = []
      querySnapshot.forEach((doc) => {
        people.push({ value: doc.data().id, label: doc.data().name, group: doc.data().groupId })
      })
      people.sort((a, b) => {
        return a.label.localeCompare(b.label)
      })
      setGuestsData(people)
    })
  }, [])

  const editGuest = (e, oldName) => {
    e.preventDefault()
    GetIdFromFieldName('people', oldName, 'name').then((id) => {
      UpdateOneDocument('people', id, inputRef.current.value, 'name')
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (name !== undefined && name !== '') {
      const docRef = doc(collection(db, 'people'))
      setDoc(docRef, {
        name: name,
        id: docRef.id,
        groupId: null,
        meat: true
      })
      $('#GuestsForm')[0].reset()
      setName()
    } else {
      window.alert('Zadaj meno!')
    }
  }

  const deleteGuest = (e) => {
    const id = e.target.dataset.id
    deleteDoc(doc(db, 'people', id))
  }
  return (
    <React.Fragment>
      <Navbar />
      <h1 className="mainHeading">Správa hostí</h1>
      <form id="GuestsForm" className="formWrap">
        <input
          className="formInput"
          placeholder="Name"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <button onClick={handleSubmit}>Pridať</button>
      </form>

      <div className="searchBar">
        {' '}
        <input
          id="search"
          type="text"
          placeholder="Vyhľadávaj podľa mena"
          onChange={handleSearch}
        />
      </div>

      <Table data={tableData} className="dbTable">
        {(guests) => (
          <>
            <Header className="header">
              <HeaderRow>
                <HeaderCell>P.Č.</HeaderCell>
                <HeaderCell>Meno</HeaderCell>
                <HeaderCell>Skupina</HeaderCell>
                <HeaderCell>Upraviť</HeaderCell>
                <HeaderCell>Vymazať</HeaderCell>
              </HeaderRow>
            </Header>
            <Body>
              {guests.map(
                (item) => (
                  setNewName(item.label),
                  (
                    <Row key={item.value} item={item}>
                      <Cell className="order">{guests.indexOf(item) + 1}.</Cell>
                      <Cell>{item.label}</Cell>
                      <Cell>{item.group != null ? item.group : ''}</Cell>
                      <Cell>
                        {' '}
                        <Popup
                          trigger={
                            <button className="popupBtn">
                              <FontAwesomeIcon className="deleteIcon" icon={faPen} />
                            </button>
                          }
                          position="right center">
                          <input
                            type="text"
                            defaultValue={item.label}
                            onChange={(e) => setNewName(e.target.value)}
                            ref={inputRef}
                          />
                          <button onClick={(e) => editGuest(e, item.label)}>Uložiť zmeny</button>
                        </Popup>
                      </Cell>
                      <Cell>
                        {' '}
                        <FontAwesomeIcon
                          className="deleteIcon"
                          icon={faXmark}
                          data-id={item.value}
                          onClick={deleteGuest}
                        />
                      </Cell>
                    </Row>
                  )
                )
              )}
            </Body>
          </>
        )}
      </Table>
    </React.Fragment>
  )
}

export default ManageGuests
