/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Navbar from '../../../../components/Navbar/Navbar'
import { db } from '../../../../../fire'
import Select from 'react-select'
import {
  collection,
  query,
  onSnapshot,
  getDoc,
  deleteDoc,
  where,
  doc,
  updateDoc,
  setDoc
} from 'firebase/firestore'
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell
} from '@table-library/react-table-library/table'
import { GetSpecificDocuments, UpdateOneDocument } from '../../../../../common/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import './ManageGuestGroup.css'

const AddGuestGroup = () => {
  const navigate = useNavigate()
  const [createGroupClass, setCreateGroupClass] = useState('hide')
  const [code, setCode] = useState()
  const [selected, setSelected] = useState()
  const [groupName, setGroupName] = useState('')
  const [groups, setGroups] = useState([])
  const [announcement, setAnnouncement] = useState(1)
  const [invintation, setInvintation] = useState(1)
  const [announcementTotal, setAnnouncementTotal] = useState(1)
  const [invintationTotal, setInvintationTotal] = useState(1)

  const [search, setSearch] = React.useState('')

  const tableData = {
    nodes: groups.filter((item) => item.label.toLowerCase().includes(search.toLowerCase()))
  }

  const handleSearch = (event) => {
    setSearch(event.target.value)
  }

  const GenerateCode = () => {
    return Math.random()
      .toString(36)
      .replace(/[^a-z,0-9]+/g, '')
      .substr(0, 5)
      .toUpperCase()
  }

  const createGroup = () => {
    setCreateGroupClass('show')
    let code = null
    let docRef = null
    let docSnap = null
    let notUniqueCode = true

    while (notUniqueCode) {
      code = GenerateCode()
      docRef = doc(db, 'group', code)
      docSnap = getDoc(docRef).then((document) => {
        if (document.exists()) {
          notUniqueCode = true
        } else {
          setCode(code)
          notUniqueCode = false
        }
      })
      return notUniqueCode
    }
  }
  const [guestOption, setGuestsOption] = useState([])

  const ReadRealTimeData = (q, setData) => {
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = []
      let InvintationTotal = 0
      let AnnouncementTotal = 0
      querySnapshot.forEach((doc) => {
        data.push({
          value: doc.data().id,
          label: doc.data().name,
          announcement: doc.data().announcement,
          invintation: doc.data().invintation
        })

        AnnouncementTotal +=
          doc.data().announcement != undefined ? Number(doc.data().announcement) : 0

        InvintationTotal += doc.data().invintation != undefined ? Number(doc.data().invintation) : 0
      })
      setData(data)

      setInvintationTotal(InvintationTotal)
      setAnnouncementTotal(AnnouncementTotal)
    })
    return setData
  }

  useEffect(() => {
    const q = query(collection(db, 'people'), where('groupId', '==', null))
    ReadRealTimeData(q, setGuestsOption)
  }, [])

  useEffect(() => {
    const q = query(collection(db, 'group'))
    ReadRealTimeData(q, setGroups)
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (selected.length > 0 && groupName != '') {
      const docRef = setDoc(doc(db, 'group', code), {
        id: code,
        name: groupName,
        announcement: announcement,
        invintation: invintation
      })
      selected.map((member) => {
        const memberRef = doc(db, 'people', member.value)
        updateDoc(memberRef, {
          groupId: code
        })
      })
      setSelected()
      setGroupName('')
      setCreateGroupClass('hide')
    } else {
      window.alert('Zadaj členov skupiny!')
    }
  }

  const editGroup = (e, id) => {
    e.preventDefault()
    navigate(`/managegroup/${id}`)
  }

  const deleteGroup = (e) => {
    const id = e.target.dataset.id
    if (id) {
      GetSpecificDocuments('people', id, 'groupId')
        .then((personId) => {
          personId.map((person) => {
            UpdateOneDocument('people', person.id, null, 'groupId').then(() => {
              console.log('updated')
            })
          })
        })
        .then(() => {
          deleteDoc(doc(db, 'group', id))
        })
    }
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className="PageTop">
        <h1 className="mainHeading">Skupiny</h1>
        {createGroupClass == 'hide' ? (
          <button onClick={createGroup} className="btnAdd">
            Nová
          </button>
        ) : (
          <button onClick={() => setCreateGroupClass('hide')} className="btnAdd">
            Zrušiť
          </button>
        )}
      </div>
      <form className={createGroupClass}>
        <fieldset className="fieldsetArea">
          <legend>Nová skupina - {code}</legend>
          <div className="pContainer">
            <p>
              <label htmlFor="name">Názov</label>
              <input
                type="text"
                name="name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </p>
            <p>
              <label htmlFor="name">Oznámenie počet</label>
              <input
                type="number"
                name="announcement"
                value={announcement}
                onChange={(e) => setAnnouncement(e.target.value)}
              />
            </p>
            <p>
              <label htmlFor="name">Pozvánka počet</label>
              <input
                type="number"
                name="invintation"
                value={invintation}
                onChange={(e) => setInvintation(e.target.value)}
              />
            </p>
          </div>
          <Select
            isMulti
            options={guestOption}
            onChange={(e) => setSelected(e)}
            value={selected || ''}
          />
          <button className="btnAdd" onClick={handleSubmit}>
            Vytvoriť
          </button>
        </fieldset>
      </form>
      <div className="searchBar">
        {' '}
        <input
          id="search"
          type="text"
          placeholder="Vyhľadávaj podľa názvu"
          onChange={handleSearch}
        />
      </div>
      <table className="dbTable">
        <thead>
          <tr>
            <th>Oznámenia celkom</th>
            <th>Pozvánky celkom</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{announcementTotal}</th>
            <th>{invintationTotal}</th>
          </tr>
        </tbody>
      </table>
      <Table data={tableData} className="dbTable">
        {(groups) => (
          <>
            <Header className="header">
              <HeaderRow>
                <HeaderCell>P.Č.</HeaderCell>
                <HeaderCell>Kód</HeaderCell>
                <HeaderCell>Názov</HeaderCell>
                <HeaderCell>Oznámenie</HeaderCell>
                <HeaderCell>Pozvánka</HeaderCell>
                <HeaderCell>Upraviť</HeaderCell>
                <HeaderCell>Vymazať</HeaderCell>
              </HeaderRow>
            </Header>
            <Body>
              {groups.map((item) => (
                <Row key={item.value} item={item}>
                  <Cell className="order">{groups.indexOf(item) + 1}.</Cell>
                  <Cell>{item.value}</Cell>
                  <Cell>{item.label}</Cell>
                  <Cell>{item.announcement}</Cell>
                  <Cell>{item.invintation}</Cell>
                  <Cell>
                    {' '}
                    <FontAwesomeIcon
                      className="deleteIcon"
                      icon={faPen}
                      onClick={(e) => editGroup(e, item.value)}
                    />
                  </Cell>
                  <Cell>
                    {' '}
                    <FontAwesomeIcon
                      className="deleteIcon"
                      icon={faXmark}
                      data-id={item.value}
                      onClick={deleteGroup}
                    />
                  </Cell>
                </Row>
              ))}
            </Body>
          </>
        )}
      </Table>
    </React.Fragment>
  )
}

export default AddGuestGroup
