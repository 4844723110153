/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar'
import localStorage from 'local-storage'
import { auth } from '../../../fire'
import { signInWithEmailAndPassword, browserLocalPersistence, setPersistence } from 'firebase/auth'
import './Login.css'

const Login = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [home, setHome] = useState(false)
  const [emailValid, setEmailValid] = useState()

  const handleEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value !== '') {
      setEmailValid('emailValidLogin emailValid')
    } else {
      setEmailValid()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            setHome(true)
          })
          .catch((error) => {
            window.alert(error)
          })
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
      })
  }
  /* while (localStorage.get('pocemon')) {
    auth.signInWithEmailAndPassword(email, password)
  }
  */
  if (home === true) {
    localStorage.set('Pocemon', true)
    return <Navigate to="/db" />
  }
  return (
    <div className="Bac Signup Login">
      <Navbar />
      <form className="Login-main" onSubmit={handleSubmit}>
        <ul>
          <li>
            <div className="containerDiv containerDivLogin">
              <input type="email" id="email" required onChange={handleEmail} />
              <label htmlFor="email" className="label-name">
                <span className={`content-name ${emailValid}`}>Email*</span>
              </label>
            </div>
            <div className="containerDiv containerDivLogin">
              <input
                type="password"
                id="password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="password" className="label-name">
                <span className="content-name">Heslo*</span>
              </label>
            </div>
          </li>
        </ul>
        <div className="btn-div-l">
          <button className="container-btn-login">Prihlasit sa</button>
        </div>
      </form>
    </div>
  )
}

export default Login
