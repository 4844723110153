import React from 'react'
import { HashRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import * as routes from './common/routes'
import Home from './frontend/screens/Home/Home'
import Galery from './frontend/screens/Galery/Galery'
import Ucast from './frontend/screens/Ucast/Ucast'
import Login from './frontend/screens/Login/Login'
import Db from './frontend/screens/Admin/JankovciDB/JankovciDB'
import ManageGuestGroup from './frontend/screens/Admin/Guests/ManageGuestGroup/ManageGuestGroup'
import ManageGuestGroupId from './frontend/screens/Admin/Guests/ManageGuestGroup/ManageGuestGroupId'
import ManageGuests from './frontend/screens/Admin/Guests/ManageGuests/ManageGuests'
import localStorage from 'local-storage'

const PrivateWrapper = () => {
  const loginToken = localStorage.get('Pocemon')
  if (loginToken === true || loginToken === false) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

const Router = () => (
  <HashRouter>
    <Routes>
      <Route exact path={routes.HOME} element={<Home />} />
      <Route exact path={routes.GALERY} element={<Galery />} />
      <Route exact path={routes.UCAST} element={<Ucast />} />
      <Route exact path={routes.LOGIN} element={<Login />} />
      <Route element={<PrivateWrapper />}>
        <Route exact path={routes.DB} element={<Db />} />
        <Route exact path={routes.MANAGEGROUP} element={<ManageGuestGroup />} />
        <Route exact path={routes.MANAGEGROUPID} element={<ManageGuestGroupId />} />
        <Route exact path={routes.MANAGEGUESTS} element={<ManageGuests />} />
      </Route>
      <Route exact path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
    </Routes>
  </HashRouter>
)
export default Router
