/* eslint-disable no-unused-vars */
import Navbar from '../../components/Navbar/Navbar'
import React, { useState, useEffect } from 'react'
import { Choose, When, Otherwise } from 'react-control-statements'
import './Galery.css'
import '../../components/Loader.css'

const Galery = () => {
  const [displayImg, setDisplayImg] = useState([])
  function importAll(r) {
    let images = {}
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item)
    })
    return images
  }
  useEffect(() => {
    const images = importAll(
      require.context('../../components/media/galery', false, /\.(png|jpe?g|svg)$/)
    )
    const image = []
    for (const [key, value] of Object.entries(images)) {
      image.push(<img key={key} className="imgGalery" src={value} />)
    }
    setDisplayImg(image)
    /*
    listAll(storageRef).then((res) => {
      let imageArray = []
      
      res.items.forEach((itemRef) => {
        getDownloadURL(itemRef).then((url) => {
          imageArray.push({ name: itemRef.name, url: url })
          const sorted = imageArray.sort((a, b) => (a.name < b.name ? 1 : -1))
          const image = sorted.map((i) => <img key={i.name} className="imgGalery" src={i.url} />)
          setDisplayImg(image)
        })
      })
      
      return setDisplayImg
    })
    */
  }, [])

  return (
    <div>
      <Navbar />
      {displayImg}
    </div>
  )
}
export default Galery
