import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import Countdown from 'react-countdown'
import './Home.css'

const HomePage = () => {
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span className="counter">
        <span>
          <h3>DNI</h3>
          <p>{days}</p>
        </span>
        <span>
          <h3>HODINY</h3>
          <p>{hours}</p>
        </span>
        <span>
          <h3>MINÚTY</h3>
          <p>{minutes}</p>
        </span>
        <span>
          <h3>SEKUNDY</h3>
          <p>{seconds}</p>
        </span>
      </span>
    )
  }

  return (
    <div className="Bac">
      <Navbar />
      <div className="content">
        <p className="mainPageText">
          Naše &quot;ÁNO&quot;
          <br />
          si povieme o
        </p>
        <Countdown date="08/27/2022 04:00:00 PM" renderer={renderer} />
      </div>
    </div>
  )
}
export default HomePage
