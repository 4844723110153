import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { If } from 'react-control-statements'
import Navbar from '../../../components/Navbar/Navbar'
import { GetAllDocuments } from '../../../../common/functions'
import './JankovciDB.css'

const Databaza = ({ ...props }) => {
  const [data, setData] = useState()
  const [come, setCome] = useState()
  const [noCome, setNoCome] = useState()
  const [unknown, setUnknown] = useState()
  const [glutenfreeNumber, setGlutenfreeNumber] = useState(0)
  const [dairyfreeNumber, setDairyfreeNumber] = useState(0)
  const [withMeatNumber, setWithMeatNumber] = useState(0)
  const [withoutMeatNumber, setWithoutMeatNumber] = useState(0)
  const [bigPortionNumber, setBigPortionNumber] = useState(0)
  const [kidsPortionNumber, setKidsPortionNumber] = useState(0)

  // const params = new URLSearchParams(location.search) // eslint-disable-line
  // const category = params.get('people')

  useEffect(() => {
    GetAllDocuments('people').then((people) => {
      setData(people)
    })
  }, [props.source])

  useEffect(() => {
    const pridu = []
    const nepridu = []
    const nepotvrdene = []
    let bezlepkove = 0
    let bezlaktozove = 0
    let masite = 0
    let vegetarianske = 0
    let dospelaPorcia = 0
    let detskaPorcia = 0
    if (data) {
      data.map((e) => {
        if (e.ucast && e.ucast.value === 0) {
          const glutenfree = e.meal && e.meal.some((val) => val.value === 2) ? 'Áno' : 'Nie'
          const dairyfree = e.meal && e.meal.some((val) => val.value === 3) ? 'Áno' : 'Nie'
          const meat = e.meat == true ? 'Áno' : 'Nie'
          const portion = e.portion ? e.portion.label : 'Nevybrané'
          glutenfree == 'Áno' ? (bezlepkove += 1) : bezlepkove
          dairyfree == 'Áno' ? (bezlaktozove += 1) : bezlaktozove
          meat == 'Áno' ? (masite += 1) : (vegetarianske += 1)
          if (e.portion) {
            e.portion.value == 1 ? (dospelaPorcia += 1) : (detskaPorcia += 1)
          }
          pridu.push({
            name: e.name,
            meat: meat,
            glutenfree: glutenfree,
            dairyfree: dairyfree,
            portion: portion
          })
        } else if (e.ucast && e.ucast.value === 1) {
          nepridu.push(e)
        } else {
          nepotvrdene.push(e)
        }
        const Pridu = pridu.map((e) => (
          <table className="dbTable" key={e.name}>
            <tr>
              <td>{pridu.indexOf(e) + 1}</td>
              <td>{e.name}</td>
              <td>{e.meat}</td>
              <td>{e.glutenfree}</td>
              <td>{e.dairyfree}</td>
              <td>{e.portion}</td>
            </tr>
          </table>
        ))
        const Nepridu = nepridu.map((e) => (
          <table className="dbTable" key={e.name}>
            <tr>
              <td>{nepridu.indexOf(e) + 1}</td>
              <td>{e.name}</td>
            </tr>
          </table>
        ))
        const Nepotvrdene = nepotvrdene.map((e) => (
          <table className="dbTable" key={e.name}>
            <tr>
              <td>{nepotvrdene.indexOf(e) + 1}</td>
              <td>{e.name}</td>
            </tr>
          </table>
        ))
        setCome(Pridu)
        setNoCome(Nepridu)
        setUnknown(Nepotvrdene)
        return setCome, setNoCome, setUnknown
      })
    }
    setGlutenfreeNumber(bezlepkove)
    setDairyfreeNumber(bezlaktozove)
    setWithMeatNumber(masite)
    setWithoutMeatNumber(vegetarianske)
    setBigPortionNumber(dospelaPorcia)
    setKidsPortionNumber(detskaPorcia)
  }, [data])

  return (
    <React.Fragment className="db">
      <Navbar />
      <If condition={come}>
        <h2 className="dbHeading">Pridu</h2>
        <table className="dbTable">
          <tr>
            <th>p.č</th>
            <th>Meno</th>
            <th>
              Mäso
              <br />
              <br />
              (mäso = {withMeatNumber})
              <br />
              (ryba = {withoutMeatNumber})
            </th>
            <th>Bezlepkové ({glutenfreeNumber})</th>
            <th>Bezlaktózové ({dairyfreeNumber})</th>
            <th>
              Porcia
              <br />
              <br />
              (veľká = {bigPortionNumber})
              <br />
              (detská = {kidsPortionNumber})
            </th>
          </tr>
        </table>
        {come}
      </If>
      <If condition={noCome}>
        <h2 className="dbHeading">Nepridu</h2>
        <table className="dbTable">
          <tr>
            <th>p.č</th>
            <th>Meno</th>
          </tr>
        </table>
        {noCome}
      </If>
      <If condition={unknown}>
        <h2 className="dbHeading">Nepotvrdene</h2>
        <table className="dbTable">
          <tr>
            <th>p.č</th>
            <th>Meno</th>
          </tr>
        </table>
        {unknown}
      </If>
    </React.Fragment>
  )
}
Databaza.propTypes = {
  source: PropTypes.object,
  location: PropTypes.object
}
export default Databaza
