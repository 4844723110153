/* eslint-disable no-unused-vars */

import { FirebaseAuthProvider, FirebaseDataProvider } from 'react-admin-firebase'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage, ref } from 'firebase/storage'

export const config = {
  apiKey: 'AIzaSyCeUK-EnLx6TkilOcwm8tnCtracAtxq8b8',
  authDomain: 'jankovci-e6bb5.firebaseapp.com',
  projectId: 'jankovci-e6bb5',
  storageBucket: 'jankovci-e6bb5.appspot.com',
  messagingSenderId: '547054087353',
  appId: '1:547054087353:web:d936ee2a3c352c2216ff68',
  measurementId: 'G-GPM0VEB29G'
}
export const fire = initializeApp(config)

export const db = getFirestore(fire)
const storage = getStorage(fire)
export const storageRef = ref(storage, 'images/')
// Create a reference from a Google Cloud Storage URI
export const dataProvider = FirebaseDataProvider(config)
export const authProvider = FirebaseAuthProvider(config)

export const auth = getAuth()
