import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './i18next'
import './frontend/components/Loader.css'

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense
      fallback={
        <>
          <p className="LoadingTitle">Svadobná stránka sa načítava</p>
          <div className="circle"> </div>
          <div className="circle-small"> </div>
          <div className="circle-big"> </div>
          <div className="circle-inner-inner"> </div>
          <div className="circle-inner"> </div>
        </>
      }>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
