import { collection, doc, getDocs, getDoc, where, query, runTransaction } from 'firebase/firestore'
// import { isString } from 'lodash'
import { db } from '../fire'

export async function GetAllDocuments(col) {
  const Col = collection(db, col)
  const Snapshot = await getDocs(Col)
  const Data = Snapshot.docs.map((doc) => doc.data())

  // const dataArray = getOneDocument(db, 'hosts', code)
  // dataArray.then((data) => {
  //  console.log(data)
  // })

  return Data
}

export async function GetOneDocument(col, document) {
  const Doc = doc(db, col, document)
  const docSnap = await getDoc(Doc)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    // doc.data() will be undefined in this case
    return null
  }
}

export async function GetSpecificDocuments(col, match, fieldName) {
  const q = query(collection(db, col), where(fieldName, '==', match))
  const querySnapshot = await getDocs(q)
  const data = querySnapshot.docs.map((doc) => doc.data())
  return data
}

export async function GetIdFromFieldName(col, match, fieldName) {
  const q = query(collection(db, col), where(fieldName, '==', match))
  const querySnapshot = await getDocs(q)
  let data = undefined
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    data = doc.id
    return data
  })
  return data
}

export async function UpdateOneDocument(col, document, newValue, fieldName) {
  const docRef = doc(db, col, document)
  try {
    await runTransaction(db, async (transaction) => {
      const document = await transaction.get(docRef)
      if (!document.exists()) {
        throw 'Document does not exist!'
      }
      transaction.update(docRef, { [fieldName]: newValue })
    })
  } catch (e) {
    console.error(e)
  }
}
