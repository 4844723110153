/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { If } from 'react-control-statements'
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useTranslation } from 'react-i18next'
import pozvanka from '../../components/media/codeGuideSK.png'
import Navbar from '../../components/Navbar/Navbar'
import {
  GetOneDocument,
  GetIdFromFieldName,
  UpdateOneDocument,
  GetSpecificDocuments
} from '../../../common/functions'
import './Ucast.css'

const Ucast = () => {
  const [showForm, setShowForm] = useState('hidden')
  const [acceptedMsg, setAcceptedMsg] = useState('hidden')
  const [code, setCode] = useState()
  const [hostia, setHosts] = useState([])
  const [host, setHost] = useState()
  const { t, i18n } = useTranslation()

  const optMeal = [
    { value: 2, label: `${t('Ucast.mealOpt.3')}` },
    { value: 3, label: `${t('Ucast.mealOpt.4')}` }
  ]
  const optPortion = [
    { value: 0, label: `${t('Ucast.portionOpt.1')}` },
    { value: 1, label: `${t('Ucast.portionOpt.2')}` }
  ]
  const optUcast = [
    { value: 0, label: `${t('Ucast.ucastOpt.1')} :)` },
    { value: 1, label: `${t('Ucast.ucastOpt.2')} :(` }
  ]
  // eslint-disable-next-line no-unused-vars
  const [lang, setLanguage] = useState('sk')
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const handleAccepted = (e) => {
    e.preventDefault()
    setShowForm('hidden')
    setAcceptedMsg('showDiv')
  }
  const handleEditingAgain = (e) => {
    e.preventDefault()
    setShowForm('show')
    setAcceptedMsg('hidden')
  }

  const handleOptionChange = (e, i, fieldName) => {
    GetIdFromFieldName('people', i, 'name')
      .then((id) => {
        UpdateOneDocument('people', id, e, fieldName)
      })
      .then(() => {
        GetOneDocument('group', code).then((data) => {
          if (data !== null) {
            setHosts(data)
          }
        })
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    GetOneDocument('group', code).then((data) => {
      if (data !== null) {
        setHosts(data)
        setShowForm('show')
      } else {
        confirmAlert({
          title: `${t('Ucast.basic.10')}`,
          message: `${t('Ucast.basic.11')}`,
          buttons: [
            {
              label: `${t('Ucast.basic.12')}`
            }
          ]
        })
      }
    })
  }

  useEffect(() => {
    const ludia = []
    if (hostia !== undefined && hostia.id !== undefined) {
      GetSpecificDocuments('people', hostia.id, 'groupId').then((data) => {
        data.map((e) => {
          if (e.empty) {
            console.log('No matching documents.')
            return
          }
          if (ludia.length === []) {
            ludia.push({
              name: e.name,
              jedlo: e.meal,
              porcia: e.portion !== undefined ? e.portion.value : e.portion,
              ucast: e.ucast !== undefined ? e.ucast.value : e.ucast,
              meat: e.meat !== undefined ? e.meat : 1
            })
          } else if (ludia.some((i) => i.name === e.name)) {
            ludia.map((person) => {
              if (person.name === e.name) {
                ludia[ludia.indexOf(person)] = {
                  name: e.name,
                  jedlo: e.meal,
                  porcia: e.portion !== undefined ? e.portion.value : e.portion,
                  ucast: e.ucast !== undefined ? e.ucast.value : e.ucast,
                  meat: e.meat !== undefined ? e.meat : 1
                }
              }
            })

            /* Vendors contains the element we're looking for */
          } else {
            ludia.push({
              name: e.name,
              jedlo: e.meal,
              porcia: e.portion !== undefined ? e.portion.value : e.portion,
              ucast: e.ucast !== undefined ? e.ucast.value : e.ucast,
              meat: e.meat !== undefined ? e.meat : 1
            })
          }

          setHosts(ludia)

          const host = ludia.map((e) => (
            <table className="ucastTable" key={e.name}>
              <tbody>
                <tr className="radio-toolbar">
                  <td className="choosen">
                    <p className="meno">{e.name}</p>
                    <p className="tableHeading">{t('Ucast.basic.6')}</p>
                  </td>
                  <If condition={e.ucast === undefined || e.ucast === 0}>
                    <td>
                      <Select
                        defaultValue={optUcast[e.ucast]}
                        options={optUcast}
                        onChange={(i) => handleOptionChange(i, e.name, 'ucast')}
                      />
                    </td>
                    <td className="radio-toolbar">
                      <p className="tableHeading">{t('Ucast.basic.4')}</p>
                      <Select
                        defaultValue={e.jedlo}
                        options={optMeal}
                        isMulti
                        onChange={(i) => handleOptionChange(i, e.name, 'meal')}
                      />
                      <input
                        type="radio"
                        id={`${e.name.replace(' ', '_')}1`}
                        value="1"
                        name={`${e.name.replace(' ', '_')}`}
                        defaultChecked={e.meat}
                        onChange={() => handleOptionChange(true, e.name, 'meat')}
                      />
                      <label htmlFor={`${e.name.replace(' ', '_')}1`}>{t('Ucast.mealOpt.1')}</label>
                      <input
                        type="radio"
                        id={`${e.name.replace(' ', '_')}2`}
                        name={`${e.name.replace(' ', '_')}`}
                        defaultChecked={!e.meat}
                        value="1"
                        onChange={() => handleOptionChange(false, e.name, 'meat')}
                      />
                      <label htmlFor={`${e.name.replace(' ', '_')}2`}>{t('Ucast.mealOpt.2')}</label>
                    </td>
                    <td>
                      <p className="tableHeading">{t('Ucast.basic.5')}</p>
                      <Select
                        defaultValue={optPortion[e.porcia]}
                        options={optPortion}
                        onChange={(i) => handleOptionChange(i, e.name, 'portion')}
                      />
                    </td>
                  </If>
                  <If condition={e.ucast !== undefined && e.ucast !== 0}>
                    <td>
                      <Select
                        defaultValue={optUcast[e.ucast]}
                        options={optUcast}
                        onChange={(i) => handleOptionChange(i, e.name, 'ucast')}
                      />
                    </td>
                  </If>
                </tr>
              </tbody>
            </table>
          ))
          setHost(host)
        })
        return setHosts
      })
    }
  }, [hostia, host])

  return (
    <div className="codeDiv">
      <Navbar />
      <form className="codeForm" onSubmit={handleSubmit}>
        <h1 className="codeNum">{t('Ucast.basic.1')}</h1>
        <input
          required
          className="code"
          onChange={(e) => {
            setCode(e.target.value)
          }}
          type="text"
        />
        <div className="btn-div-l div-btn">
          <button className="container-btn btn-color">{t('Ucast.basic.2')}</button>
        </div>
      </form>
      <form className={showForm}>
        <If condition={hostia}>
          <table className="ucastTable hlavicka">
            <thead>
              <tr>
                <th>Hotel Familia - Továrenská 827, 064 01 Stará Ľubovňa</th>
              </tr>
            </thead>
          </table>
          {host}
        </If>
        <div className="btn-div-l">
          <button className="container-btn-login btn-color container-btn" onClick={handleAccepted}>
            {t('Ucast.basic.7')}
          </button>
        </div>
      </form>
      <div className={acceptedMsg}>
        <h2>{t('Ucast.basic.8')}</h2>
        <div className="btn-div-l">
          <button className="btn-color container-btn" onClick={handleEditingAgain}>
            {t('Ucast.basic.9')}
          </button>
        </div>
      </div>
      <img className="pozvanka" src={pozvanka} alt="pozvanka" />
    </div>
  )
}

export default Ucast
