/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { If } from 'react-control-statements'
import { NavLink } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../../../fire'
import { useNavigate } from 'react-router-dom'
import localStorage from 'local-storage'
import './Navbar.css'

const Navbar = () => {
  const { t, i18n } = useTranslation()
  const [lang, setLanguage] = useState('sk')
  const loginToken = localStorage.get('Pocemon')
  const navigate = useNavigate()

  const [isAuthenticated, setIsAuthenticated] = useState(loginToken)
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    if (loginToken === true || loginToken === false) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [loginToken])

  const handleClick = () => {
    signOut(auth)
      .then(() => {
        localStorage.clear()
        navigate('/')
      })
      .catch((err) => {
        // Handle errors
        return err
      })
  }
  let activeStyle = {
    textDecoration: 'underline',
    textTransform: 'uppercase',
    color: '#97b8bb'
  }
  return (
    <div className="NavBckg">
      <h1 className="Jankovci">{t('Navbar.1')}</h1>
      <nav>
        <ul>
          <li>
            <NavLink
              exact
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/home">
              {t('Navbar.2')}
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink
              exact
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/galery">
              {t('Navbar.3')}
            </NavLink>
          </li>
        </ul>
        <ul>
          <li>
            <NavLink
              exact
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/ucast">
              {t('Navbar.4')}
            </NavLink>
          </li>
        </ul>
        <If condition={isAuthenticated}>
          <ul>
            <li>
              <NavLink
                exact
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/db">
                {t('Navbar.5')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink
                exact
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/manageguests">
                {t('Navbar.6')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink
                exact
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/managegroup">
                {t('Navbar.7')}
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <a onClick={handleClick}>{t('Navbar.8')}</a>
            </li>
          </ul>
        </If>
        <If condition={!isAuthenticated}>
          <ul>
            <li>
              <NavLink
                exact
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/login">
                {t('Navbar.9')}
              </NavLink>
            </li>
          </ul>
        </If>
        {/*
        <a className="flag" onClick={() => setLanguage('sk')}>
          🇸🇰
        </a>
        <a className="flag" onClick={() => setLanguage('en')}>
          🇺🇸
        </a>
       */}
      </nav>
    </div>
  )
}
export default Navbar
